<template>
  <!-- <v-navigation-drawer style="min-width: 285px;" permanent width="285"> -->
  <v-navigation-drawer
    :style="$store.state.fullSize ? 'min-width: 285px;' : ''"
    v-model="$store.state.newClientDrawerMenu"
    :absolute="!$store.state.fullSize"
    :temporary="!$store.state.fullSize"
    :permanent="$store.state.fullSize"
    width="285"
  >
    <v-list-item :class="['drawerOpen', 'py-5']">
      <v-list-item-avatar color="white" size="56">
        <span class="text-h5">{{ initialsAvatar }}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h6 white--text">
          {{ nameView }}
        </v-list-item-title>
        <v-list-item-subtitle class="white--text font-italic">
          {{ emailView }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav class="primaryNew--text">
      <v-list-item
        @click="openExPanel('home')"
        active-class="selItem"
        class=""
        link
        value="home"
        to="/cliente/home"
      >
        <v-list-item-icon>
          <v-icon color="primaryNew"
            >mdi-home-circle-outline</v-icon
          > </v-list-item-icon
        ><v-list-item-content>
          <v-list-item-title class="primaryNew--text">Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-expansion-panels
        v-if="
          $store.state.clientPermissions.newAreapermissions.pagamento.hasProd
        "
        class=""
        v-model="stateExPanel"
        flat
      >
        <v-expansion-panel class="menuOpened">
          <v-expansion-panel-header
            style="min-height: 44px"
            class="pa-0 align-start"
            hide-actions
          >
            <v-list-item
              @click="openExPanel(0)"
              active-class="selItem"
              class="primaryNew--text pgtos"
              link
              to="/cliente/pagamentos"
            >
              <v-list-item-icon>
                <v-icon color="primaryNew">payment</v-icon> </v-list-item-icon
              ><v-list-item-content>
                <v-list-item-title class="primaryNew--text"
                  >Pagamentos</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="menuBorderRadius">
            <v-list dense>
              <v-subheader
                class="primaryNew--text font-weight-medium subtitle-1"
                >Bancos</v-subheader
              >
              <v-list-item
                class="adm-banks"
                color="primaryNew"
                link
                to="/cliente/pagamentos/bancos"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="primaryNew" size="20"
                    >mdi mdi-bank-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primaryNew--text"
                    >Meus bancos</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-subheader
                class="primaryNew--text font-weight-medium subtitle-1"
                >Boletos</v-subheader
              >
              <v-list-item
                class="adm-boletos"
                color="primaryNew"
                link
                to="/cliente/pagamentos/adm-boletos"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="primaryNew" size="20">local_atm</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primaryNew--text"
                    >Transações</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="config-boletos"
                color="primaryNew"
                link
                to="/cliente/pagamentos/config-boletos"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="primaryNew" size="20">settings</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primaryNew--text"
                    >Configuração</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-subheader
                class="primaryNew--text font-weight-medium subtitle-1"
                >Cartões de crédito</v-subheader
              >
              <v-list-item
                class="adm-cartoes"
                color="primaryNew"
                link
                to="/cliente/pagamentos/adm-cartoes"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="primaryNew" size="20">credit_card</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primaryNew--text"
                    >Transações</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="config-cartoes"
                color="primaryNew"
                link
                to="/cliente/pagamentos/config-cartoes"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon color="primaryNew" size="20">settings</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primaryNew--text"
                    >Configuração</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-subheader class="primaryNew--text font-weight-medium subtitle-1">PIX</v-subheader>
              <v-list-item
                class="pix"
                color="primaryNew"
                link
                to="/cliente/pagamentos/pix"
              >
                <v-list-item-icon class="mr-3">
                  <svg
                    width="20px"
                    height="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#5a77b3"
                      d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z"
                    />
                  </svg>
                  <!-- <font-awesome-icon :icon="['fab', 'pix']" /> -->
                  <!-- <v-icon color="primaryNew" size="20">fa-brands fa-pix</v-icon> -->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primaryNew--text"
                    >Transações PIX</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item class="config-cartoes" color="primaryNew" link
                                to="/cliente/pagamentos/config-cartoes">
                                <v-list-item-icon class="mr-3">
                                    <v-icon color="primaryNew" size="20">settings</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="primaryNew--text">Configuração</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-list-item
        @click="openExPanel('contracts')"
        active-class="selItem"
        class="primaryNew--text"
        link
        to="/cliente/meus-contratos"
      >
        <v-list-item-icon>
          <v-icon color="primaryNew">mdi-file-sign</v-icon> </v-list-item-icon
        ><v-list-item-content>
          <v-list-item-title class="primaryNew--text"
            >Meus contratos</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        @click="openExPanel('financeira')"
        active-class="selItem"
        class="primaryNew--text"
        link
        to="/cliente/area-financeira"
      >
        <v-list-item-icon>
          <v-icon color="primaryNew"
            >mdi-chart-timeline-variant-shimmer</v-icon
          > </v-list-item-icon
        ><v-list-item-content>
          <v-list-item-title class="primaryNew--text"
            >Área financeira</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        @click="toogleCerdentialsDilog"
        active-class="selItem"
        class="primaryNew--text"
        link
        value=""
      >
        <v-list-item-icon>
          <v-icon color="primaryNew">mdi-dev-to</v-icon> </v-list-item-icon
        ><v-list-item-content>
          <v-list-item-title class="primaryNew--text"
            >Área do desenvolvedor</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <Credentials
      :toogleCerdentialsDilog="toogleCerdentialsDilog"
      :cerdentialsDilog="cerdentialsDilog"
    />
  </v-navigation-drawer>
</template>
<script>
import Credentials from "../../components/clients/credentials/Credentials.vue";
export default {
  watch: {
    stateExPanel(v) {
      // console.log('wtcompar', v)
    },
    screenSize(v) {
      // console.log('wtch', v)
      this.$store.commit("defineFullSize", v);
    },
  },
  methods: {
    openExPanel(menuItem) {
      // console.log('compare')
      if (menuItem == 0) {
        this.stateExPanel = [0];
        return;
      } else {
        this.stateExPanel = null;
      }
    },
    toogleCerdentialsDilog() {
      this.cerdentialsDilog = !this.cerdentialsDilog;
    },
    getUserInfo() {
      let name = localStorage.getItem("userName");
      let email = localStorage.getItem("userEmail");
      this.nameView = name;
      this.emailView = email;
      this.initialsAvatar = name.substring(0, 2).toUpperCase();
    },
  },
  mounted() {
    console.log('computDrawer', this.$router.currentRoute.path)
    let urlSel = this.$router.currentRoute.path;
    let splitUrl = urlSel.split("/");
    let classToclick = splitUrl[splitUrl.length - 1];
    let paymentArea = urlSel.indexOf("cliente/pagamentos");
    if (paymentArea >= 0) {
      setTimeout(() => {
        document.getElementsByClassName("selItem")[0].click();
        setTimeout(() => {
          if (document.getElementsByClassName(classToclick).length) {
            document.getElementsByClassName(classToclick)[0].click();
          }
        }, 10);
      }, 500);
    }
    this.getUserInfo();
    this.$store.commit("defineFullSize", this.screenSize);
  },
  data() {
    return {
      stateExPanel: [],
      cerdentialsDilog: false,
      drawerMenu: true,
      nameView: "",
      emailView: "",
      initialsAvatar: "",
    };
  },
  computed: {
    screenSize() {
      let size = this.$vuetify.breakpoint.width;
      // console.log(size)
      return size;
    },
  },
  props: {
    // rail: Boolean
  },
  components: {
    Credentials,
  },
};
</script>
<style lang="scss" scoped>
.drawerClose {
  background-color: #6e29d8;
}

.selItem {
  background-color: #5a77b3 !important;
  color: white !important;
  font-weight: 500 !important;
  box-shadow: 0px 1px 3px rgb(100, 100, 100);
}

.selItem i,
.selItem .v-list-item__title {
  color: white !important;
  font-weight: 500 !important;
}

.drawerOpen {
  background-image: url("../../assets/newArea/drawer-img-02.jpg");
  background-position: center center;
  background-size: cover;
  display: block;
}

.menuOpened .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}

.menuBorderRadius {
  border-radius: 0 0 5px 5px;
  box-shadow: inset #ccc 0px 0px 20px -5px;
}
</style>